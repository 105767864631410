import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
// import { TokenHelper } from '../shared/Helpers/token-helper';
// import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SnotifyService } from 'ng-snotify';
import { CommonHelper } from '../shared/Helpers/common-helper';


@Injectable()

export class AuthInterceptor implements HttpInterceptor {

  constructor(private snotifyService: SnotifyService,) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let isValidToken: boolean = this.validateToken();
    const request = req.clone({
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token'),
        //'Access-Control-Allow-Origin': 'http://localhost:4200',
        //'x-authtoken': this._tokenHelper.getToken()
      }),

    });
    if (!isValidToken) {
      // this.snotifyService.error("UnAuthorized", CommonHelper.toasterSettings());
      // setTimeout(() => {
      //   window.location.href = 'https://kedge.srinsofttech.com/'
      // }, 2000);
      // throw new HttpErrorResponse({ error: 'UnAuthorized', status: 401 });
    }
    else {
      return next.handle(request).pipe(tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            return event;
          }
          else if (event instanceof HttpErrorResponse) {
            if (!isValidToken) {
              console.log('event tokemd error', event);
            }
          }
        },
        err => {
          console.log("error event: ", err);
        }));
    }
  }

  validateToken(): boolean {
    let isValid: boolean = false;
    if (localStorage.getItem('token')) {
      var decoded = new JwtHelperService().decodeToken(localStorage.getItem('token'));
      if (decoded && decoded.unique_name && decoded.role) {
        if (localStorage.getItem('id').trim() == decoded.unique_name.trim()) {
          isValid = true;
        }
      }
    }
    return isValid;
  }
}
