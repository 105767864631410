import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import * as _ from 'lodash'
import { DateRange } from 'src/app/models/PunchDetailModels/daterange';
import { PunchDetailsRequest } from 'src/app/models/PunchDetailModels/punchdetailsrequest';
import { PayrollPeriods } from 'src/app/models/ReportsModels/PayrollPeriods';
import { PunchdetailsService } from 'src/app/service/punchdetails/punchdetails.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonHelper } from 'src/app/shared/Helpers/common-helper';
import { SnotifyService } from 'ng-snotify';


@Component({
  selector: 'lms-manager-report',
  templateUrl: './manager-report.component.html',
  styleUrls: ['./manager-report.component.scss']
})
export class ManagerReportComponent implements OnInit {
  // resetForm() {
  //   throw new Error('Method not implemented.');
  // }

  @Input() public set selectedPayRoll(val:any) {
    // console.log("va", val)
    // this.selectedPayRoll="";
  }
  employeeID: string
  loading: boolean = false;
  dateRange: DateRange = new DateRange();
  punchDataRequest: PunchDetailsRequest;
  punchReportForm: FormGroup = new FormGroup({
    // departmentsreport: new FormControl('',Validators.required),
    cutOffdate: new FormControl('',Validators.required)
  })
  timeSheetEmployeeID: string = '';
  payValues: { Id: number, StartDate: string, EndDate: string, Period: string }[] = [];

  settings: { singleSelection: boolean; idField: string; textField: string; enableCheckAll: boolean; selectAllText: string; unSelectAllText: string; allowSearchFilter: boolean; limitSelection: number; clearSearchFilter: boolean; maxHeight: number; itemsShowLimit: number; searchPlaceholderText: string; noDataAvailablePlaceholderText: string; closeDropDownOnSelection: boolean; showSelectedItemsAtTop: boolean; defaultOpen: boolean; };
  deptData: { deptId: number; deptName: string; }[];
  monthlyCutOffPeriod: PayrollPeriods[] = [];
  currentPayRollEndDate: string;
  currentPayRollStartDate: string;

  toasterConfig = CommonHelper.toasterSettings();

  constructor(private _punchservice: PunchdetailsService,private sanitizer: DomSanitizer,  private ngxService: NgxUiLoaderService,    private snotifyService: SnotifyService,
    ) { }

  ngOnInit() {

    this.punchReportForm = new FormGroup({
      // departmentsreport: new FormControl('',Validators.required),
      cutOffdate: new FormControl('',Validators.required)
    })

    setTimeout(() => {
    }, 2500);
    this.getMonthlyCutOffDates()
    this.settings = {
      singleSelection: false,
      idField: 'deptId',
      textField: 'deptName',
      enableCheckAll: true,
      selectAllText: 'Choose All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 197,
      itemsShowLimit: 3,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: '',
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false,
    };


    // this.getDepartment();
  }

resetForm(){

  this.punchReportForm.reset();
  this.punchReportForm.controls.cutOffdate.setValue('')

}

  getDepartment() {

    this._punchservice.getDepartmentForreport("10").subscribe((data: any) => {

      this.deptData = data;

      console.log(this.deptData);

    })
  }

  getMonthlyCutOffDates() {

    this._punchservice.getMonthlyPayRollPeriods().subscribe((res: PayrollPeriods[]) => {
      this.monthlyCutOffPeriod = res;

      this.monthlyCutOffPeriod = _.reverse(_.sortBy(this.monthlyCutOffPeriod, 'id'));
      this.monthlyCutOffPeriod = this.monthlyCutOffPeriod.slice(0, 3);
      this.currentPayRollEndDate = this.monthlyCutOffPeriod[0].endDate;
      this.currentPayRollStartDate = this.monthlyCutOffPeriod[0].startDate;
      this.timeSheetEmployeeID = this.employeeID;
      this.monthlyCutOffPeriod.forEach((per) => {
        console.log(per.endDate,"start date")
        this.payValues.push({
          Id: per.id,
          StartDate: moment(per.startDate, 'DD/MM/YYYY').format('MM/DD/YYYY'),
          EndDate: moment(per.endDate, 'DD/MM/YYYY').format('MM/DD/YYYY'),
          Period: `${moment(per.startDate, 'DD/MM/YYYY').format('MM/DD/YYYY')} to ${moment(per.endDate, 'DD/MM/YYYY').format('MM/DD/YYYY')}`
        })
      })
      console.log(this.payValues,"dats are getting");
      this.selectedPayRoll = this.payValues[0].Id;
    }, (error) => {
      console.log('payroll', error)
    })
  }


  payrollChange(value: number): DateRange {
    let params: DateRange = new DateRange();
    console.log(params, "params");
    // this.getPayRollPeriod();
    this.monthlyCutOffPeriod.map(data => {
      if (data.id == value) {
        params.fromDate = data.startDate.toString();
        params.toDate = data.endDate.toString();
      }
    })
    return params;

  }


  reportDownload(form: FormGroup) {

this.ngxService.start();

    const formValues = this.punchReportForm.getRawValue()
    const formdata = new FormData()
    // let depIds = formValues.departmentsreport.map((x => x.deptId))
    let EmpID: string = localStorage.getItem('id');

    let filteDate  = this.payValues.find(x=> x.Id == formValues.cutOffdate)
    console.log(filteDate);
    let finaldata ={
      // departmentID: depIds.toString(),
      startDate: filteDate.StartDate,
      endDate: filteDate.EndDate,
      userID:EmpID

    }
     this._punchservice.downloadManagerReport(finaldata).subscribe((res:any)=>{
      console.log(res);
      if(!res.isSucceed){

        this.ngxService.stop();

        this.snotifyService.error(res.msg, this.toasterConfig);
      }
      else if(res.isSucceed){
        console.log("is succeddd")
        this.downloadFileInNewwindow(res['data'], 'ManagerLLOPReport.xlsx');

        this.ngxService.stop()
      }

    })
  }
  downloadFileInNewwindow(bytes: string, fileName: string): void {
    const arrayBuffer = this.base64ToArrayBuffer(bytes);
    const blob = new Blob([arrayBuffer]);

    if (window.navigator.msSaveOrOpenBlob) { // For IE:
      navigator.msSaveBlob(blob, fileName);
    } else { // For other browsers:
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(link.href);
    }
  }
  base64ToArrayBuffer(base64: string) {
    const binaryString = window.atob(base64); // Comment this if not using base64
    const bytes = new Uint8Array(binaryString.length);
    return bytes.map((byte, i) => binaryString.charCodeAt(i));
  }





}
