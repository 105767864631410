import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[lmsDefaultimage]',
  host: {
    '(error)': 'updateUrl()',
    '[src]': 'src'
  }
})
export class DefaultimageDirective {

  constructor() { }

  @Input() src: string;
  @Input() default: string;

  updateUrl() {
    this.src = "https://mdbootstrap.com/img/Photos/Avatars/avatar-2.jpg";

  }
}
