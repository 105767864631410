import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'lms-holiday-list',
    templateUrl: './policies.component.html',
    styleUrls: ['./policies.component.scss']
})
export class PoliciesComponent implements OnInit {

    ngOnInit(): void {

    }
}