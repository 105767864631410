import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'lms-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor() { }

  @HostListener('window:message', ['$event'])
  onPostMessage(event) {
    try {
      if (event.origin.indexOf("https://kedge.srinsofttech.com") > -1) {
        if (event && event.data && event.data.message == 'logout') {
          localStorage.clear();
        }
        else {
          localStorage.clear();
          localStorage.setItem('token', event.data.lms_token);
          localStorage.setItem('id', event.data.empID);
          localStorage.setItem('roles', event.data.roles);
        }
      } else {
        return;
      }
    } catch (err) { }
  };


  ngOnInit() {
  }

}
