import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NgxUiLoaderModule, NgxUiLoaderConfig, POSITION, SPINNER, PB_DIRECTION } from 'ngx-ui-loader';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { SidenavComponent } from '../sidenav/sidenav.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PunchDetailsDatePipe } from './pipes/punch-details-date-pipe';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { HoursFormatPipe } from './pipes/time-format';
import { PunchDetailsStatusPipe } from './pipes/punch-details-status-pipe'

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: 'red',
  bgsPosition: POSITION.bottomRight,
  fgsPosition: POSITION.centerCenter,
  bgsSize: 60,
  fgsColor: 'red',
  bgsType: SPINNER.ballScaleMultiple, // background spinner type
  fgsType: SPINNER.ballScaleMultiple, // foreground spinner type
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 3, // progress bar thickness
  pbColor: 'red',
}


@NgModule({
  declarations: [
    SidenavComponent,
    PunchDetailsDatePipe,
    HoursFormatPipe,
    PunchDetailsStatusPipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgMultiSelectDropDownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    MDBBootstrapModule.forRoot(),
    Ng2SmartTableModule,
  ],
  providers: [
    // { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
    DatePipe,
  ],
  exports: [
    CommonModule,
    BsDatepickerModule,
    NgMultiSelectDropDownModule,
    NgxUiLoaderModule,
    MDBBootstrapModule,
    SidenavComponent,
    Ng2SmartTableModule,
    FormsModule,
    PunchDetailsDatePipe,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ReactiveFormsModule,
    HoursFormatPipe, PunchDetailsStatusPipe


  ],
})
export class SharedModule { }
