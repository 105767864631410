import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import * as $ from 'jquery';
import { EmployeeService } from '../service/employee/employee.service';
import { Employees } from '../models/Employees';
import { DashboarddetailsService } from '../service/dashboard/dashboarddetails.service';
import { EmployeeInfo } from '../models/EmployeeInfo';
import { EmployeeSharedService } from '../shared/services/employee-shared-service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'lms-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  validatingForm: FormGroup;
  isHR: boolean = true;
  isManager: boolean = false;
  employeeName: any;
  employees: any;
  employeeId: any;
  employeeID: any;
  employeeInfo: EmployeeInfo = new EmployeeInfo();
  empImage: string;
  apiURL: string = environment.ApiBaseUrl;

  profileImage:any;

  constructor(private _employeeService: EmployeeService,
    private _dashboardService: DashboarddetailsService,
    private _employeeSharedService: EmployeeSharedService) { }

  ngOnInit() {
    this.getEmployeeInfo();

    this._employeeSharedService.employeeInfo.subscribe((value: EmployeeInfo) => {
      this.employeeName = value.empName;
      this.empImage = `${this.apiURL}/${value.imageURL}`;
    });

    this.validatingForm = new FormGroup({
      modalFormAvatarPassword: new FormControl('', Validators.required)
    });
    // this.getLocalStorageDetails();
    // this.getEmployeeInformation();
    let groupName: string = localStorage.getItem('roles')
    let splitRoles = groupName ? groupName.split(",") : [];
    splitRoles.includes("HR") ? this.isHR = true : this.isHR = false;
    splitRoles.includes("MANAGER") ? this.isManager = true : this.isManager = false
  }
  getLocalStorageDetails(): void {
    let groupName: string = localStorage.getItem('roles')
    this.employeeName = localStorage.getItem('EmployeeName')
    // if (groupName == 'ADMIN' || groupName == 'HR')
    //   this.isHR = true;
    // else
    //   this.isHR = false;
  }

  getEmployeeInfo() {
    this.employeeID = localStorage.getItem('id')
    this._dashboardService.getEmployeeInfo(this.employeeID).subscribe((res: EmployeeInfo) => {
      this.employeeInfo = res;
      this.employeeName = this.employeeInfo.empName;
      this.profileImage=this.employeeInfo.imageURL;

      console.log(this.profileImage,"images");

      localStorage.setItem('EmployeeName', this.employeeInfo.empName);
    })
  }

  getEmployeeInformation(): void {
    this.employeeId = localStorage.getItem('id');
    this._employeeService.getSingleEmoloyee(this.employeeId).subscribe((res: Employees[]) => {
      this.employees = res;
      // this.employees = this.employees.find(data => data.empID == this.employeeId);
    }, (error) => {
      console.log(error);
    })
  }

  toggleFn(event: any) {
    $("#wrapper").toggleClass("toggled")
    if ($('#wrapper').hasClass("toggled")) {
      document.getElementById('bar').style.display = 'block';
      document.getElementById('containerWidth1').style.paddingLeft = '0px';
      document.getElementById('containerWidth2').style.paddingLeft = '0px';
    }
    else {
      document.getElementById('bar').style.display = 'none';
      document.getElementById('containerWidth1').style.paddingLeft = '130px';
      document.getElementById('containerWidth2').style.paddingLeft = '130px';

    }
  }

  get modalFormAvatarPassword() {
    return this.validatingForm.get('modalFormAvatarPassword');
  }

  logout(): void {
    localStorage.clear();



  }
}
