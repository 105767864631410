import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'boolean'
})
export class BooleanPipe implements PipeTransform {

  transform(value: boolean, exponent: string): string {
    return !value ? 'yes' : 'no';
  }

}
