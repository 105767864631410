import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'

@Injectable({
    providedIn: 'root'
})
export class ManagerGuard implements CanActivate {

    constructor(private router: Router) {
    }

    canActivate(): boolean {
        let groupName: string = localStorage.getItem('roles')
        let splitRoles = groupName.split(",")
        if (splitRoles.includes("MANAGER"))
            return true;
        else {
            this.router.navigate(['dashboard']);
            return false;
        }

    }
}