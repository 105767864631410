import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { PunchDetailsRequest } from '../../models/PunchDetailModels/punchdetailsrequest';
import { PunchDetailsResponse } from '../../models/PunchDetailModels/PunchDetailsResponse';
import { PunchdetailsService } from '../../service/punchdetails/punchdetails.service';
import * as moment from "moment";
import { Departments } from 'src/app/models/PunchDetailModels/department';
import { Employees } from 'src/app/models/PunchDetailModels/employees';
import { FormGroup, FormControl } from '@angular/forms';
import { DateRange } from '../../models/PunchDetailModels/daterange'
import { DatePeriod } from 'src/app/models/DatePeriod';
import * as _ from 'lodash'
import { PayrollPeriods } from 'src/app/models/ReportsModels/PayrollPeriods';
import { DashboarddetailsService } from 'src/app/service/dashboard/dashboarddetails.service';
import { EmployeeSharedService } from 'src/app/shared/services/employee-shared-service';
import { EmployeeInfo } from 'src/app/models/EmployeeInfo';
import { ManagerReportComponent } from './manager-report/manager-report.component';
import { MisspunchComponent } from './misspunch/misspunch.component';

@Component({
  selector: 'lms-punchdetails',
  templateUrl: './punchdetails.component.html',
  styleUrls: ['./punchdetails.component.scss']
})
export class PunchdetailsComponent implements OnInit {
  reportmodel: boolean = false;
  loading: boolean = false;
  employeeID: string;
  employeeRole: any;
  timeSheetEmployeeID: string = '';
  timeSheetEmployeeName: string = '';
  isManager: boolean = false
  punchDataResponse: PunchDetailsResponse[] = [];
  punchDataRequest: PunchDetailsRequest;
  departments: Departments[] = [];
  employees: Employees[] = [];
  isEmployeeDisabled: boolean = true;
  headElements = ['Date', 'Shift', 'In Time', 'Out Time', 'Punch Rcrd', 'In Dur', 'Out Dur', 'Total Hrs', 'Status', 'Shortage'];
  currentPayRollEndDate: string;
  currentPayRollStartDate: string;
  // lastMonthPayRollEndDate: string;
  // lastMonthPayRollStartDate: string;
  // previousMonthPayRollEndDate: string;
  // previousMonthPayRollStartDate: string;
  payrollValues: any;
  payValues: { Id: number, StartDate: string, EndDate: string, Period: string }[] = [];
  dynamicValue: any;
  dateRange: DateRange = new DateRange();

  @ViewChild(ManagerReportComponent, { static: false }) managerReportComponent: ManagerReportComponent;

  @ViewChild(MisspunchComponent, { static: false }) MisspunchComponent: MisspunchComponent;


  punchDetailsForm: FormGroup = new FormGroup(
    {
      payrollPeriod: new FormControl(1),
      departmentName: new FormControl(null),
      EmployeeName: new FormControl()
    }
  )
  monthlyCutOffPeriod: PayrollPeriods[] = [];
  selectedPayRoll: any;
  settings: { singleSelection: boolean; idField: string; textField: string; enableCheckAll: boolean; selectAllText: string; unSelectAllText: string; allowSearchFilter: boolean; limitSelection: number; clearSearchFilter: boolean; maxHeight: number; itemsShowLimit: number; searchPlaceholderText: string; noDataAvailablePlaceholderText: string; closeDropDownOnSelection: boolean; showSelectedItemsAtTop: boolean; defaultOpen: boolean; };
  data: { item_id: number; item_text: string; }[];
  punchReportForm: FormGroup
  constructor(private _punchservice: PunchdetailsService,
    private _dashboardService: DashboarddetailsService,
    private _employeeSharedService: EmployeeSharedService) { }

  ngOnInit(): void {
    this.data = [
      { item_id: 1, item_text: 'Hanoi' },
      { item_id: 2, item_text: 'Lang Son' },
      { item_id: 3, item_text: 'Vung Tau' },
      { item_id: 4, item_text: 'Hue' },
      { item_id: 5, item_text: 'Cu Chi' },
    ];
    this.settings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      enableCheckAll: true,
      selectAllText: 'Choose All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 197,
      itemsShowLimit: 3,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: '',
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false,
    };
    this.setLocalStorageData();
    this.getMonthlyCutOffDates();
    let roleSplit = this.employeeRole ? this.employeeRole.split(",") : '';
    if (roleSplit.includes("MANAGER") || (roleSplit.includes("HR") && roleSplit.includes("MANAGER"))) {
      this.isManager = true;
      this.getDepartments();
    }

    this._employeeSharedService.employeeInfo.subscribe((info: EmployeeInfo) => {
      if (!info.empName && !info.reportingManagerId)
        this.getEmployeeInfo();
    });
  }


  setLocalStorageData(): void {
    this.employeeID = localStorage.getItem('id');
    this.employeeRole = localStorage.getItem('roles');
  }

  getEmployeeInfo() {
    this._dashboardService.getEmployeeInfo(localStorage.getItem('id')).subscribe((res: EmployeeInfo) => {
      this._employeeSharedService.updateEmployeeInfo(res);
      localStorage.setItem('EmployeeName', res.empName);
    })
  }

  getMonthlyCutOffDates() {

    this._punchservice.getMonthlyPayRollPeriods().subscribe((res: PayrollPeriods[]) => {
      this.monthlyCutOffPeriod = res;
      this.monthlyCutOffPeriod = _.reverse(_.sortBy(this.monthlyCutOffPeriod, 'id'));
      this.monthlyCutOffPeriod = this.monthlyCutOffPeriod.slice(0, 3);
      this.currentPayRollEndDate = this.monthlyCutOffPeriod[0].endDate;
      this.currentPayRollStartDate = this.monthlyCutOffPeriod[0].startDate;
      this.timeSheetEmployeeID = this.employeeID;
      this.getPunchDetails();
      this.monthlyCutOffPeriod.forEach((per) => {
        this.payValues.push({


          Id: per.id,
          StartDate: moment(per.startDate, 'DD/MM/YYYY').format('MM/DD/YYYY'),
          EndDate: moment(per.endDate, 'DD/MM/YYYY').format(' MM/DD/YYYY'),
          Period: `${moment(per.startDate, 'DD/MM/YYYY').format('MM/DD/YYYY')} to ${moment(per.endDate, 'DD/MM/YYYY').format(' MM/DD/YYYY ')}`
        })
      })
      this.selectedPayRoll = this.payValues[0].Id;
    }, (error) => {
      console.log('payroll', error)

    })
  }

  getEmployees(value: string): void {
    this._punchservice.getEmployeesByEmployee(this.employeeID, value).subscribe((res: Employees[]) => {
      this.employees = _.sortBy(res, "empName");
      this.punchDetailsForm.controls['EmployeeName'].setValue(null);
      this.punchDetailsForm.updateValueAndValidity();
      this.employees ? this.isEmployeeDisabled = false : this.isEmployeeDisabled = true;
    }, (error) => {
      console.log('GetEmployeesAPI:', error)
    });

  }

  getDepartments(): void {
    this._punchservice.getDepartments(this.employeeID).subscribe((res: Departments[]) => {
      this.departments = _.sortBy(res, 'deptName');
      // console.log("depar", this.departments)
    }, (error) => {
      console.log('DepartmentAPIError:', error)
    })
  }

  resetManagerReportComponent(){
   this.managerReportComponent.resetForm();
  }

  resetMissPunchReportComponent(){
    this.MisspunchComponent.resetForm();

  }

  getPunchDetails(): void {
    this.loading = true;
    const toDate = this.currentPayRollEndDate;
    const fromDate = this.currentPayRollStartDate;
    this.punchDataRequest = {
      fromDate: moment(fromDate, 'DD/MM/YYYY').format('MM-DD-YYYY'),
      toDate: moment(toDate, 'DD/MM/YYYY').format('MM-DD-YYYY'),
      departmentID: 0,
      employeeID: this.employeeID,
      type: 'withdateandemp',
      status: '%present%'
    }
    this._punchservice.getPunchData(this.punchDataRequest).subscribe((res: PunchDetailsResponse[]) => {
      this.punchDataResponse = [];
      this.punchDataResponse = res;
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }

  getPunchDetailsByFilter(): void {
    //let currentDate = new Date();
    // const toDate = moment(currentDate).format('MM-DD-YYYY');
    // const fromDate = moment(currentDate.setDate(currentDate.getDate() - 30)).format('MM-DD-YYYY');
    this.loading = true;
    this.dateRange = this.payrollChange(this.punchDetailsForm.controls['payrollPeriod'].value ? this.punchDetailsForm.controls['payrollPeriod'].value : 1);
    console.log(this.dateRange.fromDate, "daterange_confirm");
    let fDate = moment(this.dateRange.fromDate, 'DD/MM/YYYY').format('MM-DD-YYYY');
    let eDate = moment(this.dateRange.toDate, 'DD/MM/YYYY').format('MM-DD-YYYY');
    console.log('fdate', fDate);
    console.log('edate', eDate);
    this.punchDataRequest = {
      fromDate: fDate,
      toDate: eDate,
      departmentID: 0,
      employeeID: this.punchDetailsForm.controls['EmployeeName'].value ? this.punchDetailsForm.controls['EmployeeName'].value : this.employeeID,
      type: 'withdateandemp',
      status: '%present%'
    }
    console.log(this.punchDataRequest);
    this.timeSheetEmployeeID = this.punchDetailsForm.controls['EmployeeName'].value ? this.punchDetailsForm.controls['EmployeeName'].value : this.employeeID;
    this._punchservice.getPunchData(this.punchDataRequest).subscribe((res: PunchDetailsResponse[]) => {
      console.log('res', res)
      this.punchDataResponse = res;
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }


  payrollChange(value: number): DateRange {
    let params: DateRange = new DateRange();
    console.log(params, "params");
    // this.getPayRollPeriod();
    this.monthlyCutOffPeriod.map(data => {
      if (data.id == value) {
        params.fromDate = data.startDate.toString();
        params.toDate = data.endDate.toString();
      }
    })
    // if (value == 1) {
    //   params.toDate = moment(this.currentPayRollEndDate).format('MM-DD-YYYY');
    //   params.fromDate = moment(this.currentPayRollStartDate).format('MM-DD-YYYY');
    // }
    // else if (value == 2) {
    //   params.toDate = moment(this.lastMonthPayRollEndDate).format('MM-DD-YYYY');
    //   params.fromDate = moment(this.lastMonthPayRollStartDate).format('MM-DD-YYYY');
    // }
    // else if (value == 3) {
    //   params.toDate = moment(this.previousMonthPayRollEndDate).format('MM-DD-YYYY');
    //   params.fromDate = moment(this.previousMonthPayRollStartDate).format('MM-DD-YYYY');
    // }
    // this.punchDataRequest = {
    //   fromDate: fromDate,
    //   toDate: toDate,
    //   departmentID: 0,
    //   employeeID: this.employeeID,
    //   type: 'withdateandemp',
    //   status: '%present%'
    // }
    // this._punchservice.getPunchData(this.punchDataRequest).subscribe((res: PunchDetailsResponse[]) => {
    //   this.punchDataResponse = [];
    //   this.punchDataResponse = res;
    // }, (error) => {

    // });
    return params;

  }

  modelGenerate(){
    this.punchReportForm = new FormGroup({
      departmentsreport: new FormControl(''),
      cutOffdate: new FormControl('')
    })
    console.log(this.punchReportForm)
  }
  reportDownload(data:any){
    console.log(this.punchReportForm.controls.departmentsreport.value)

  }
  @HostListener('click', ['$event'])
  onClick(e) {
    if(!e.target.closest('.modal-content')){
      this.MisspunchComponent.resetForm();
      this.managerReportComponent.resetForm();
    }
  }
}
