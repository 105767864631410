import { SnotifyPosition }  from 'ng-snotify'

export class CommonHelper{

  static onOpenCalendarForMonth(container) : void{
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('month');
  }

  static onOpenCalendarForYear(container) : void{
    container.yearSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('year');
  }

  static setDropdownSettingsForDepts(){
    let settings = {};
    settings = {
      singleSelection: false,
      idField: 'deptId',
      textField: 'deptName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true
    };
    return settings;
  }

  static setDropdownSettingsForEmpIds(isSingleSelect: boolean = false){
    let settings = {};
    settings = {
      singleSelection: isSingleSelect,
      idField: 'empId',
      textField: 'empName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true
    };
    return settings;
  }

  static setDropdownSettingsForRMs(){
    let settings = {};
    settings = {
      singleSelection: false,
      idField: 'empId',
      textField: 'empName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true
    };
    return settings;
  }

  static setDropdownSettingsForStaticData(){
    let settings = {};
    settings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: false
    };
    return settings;
  }

  static toasterSettings(){
    let config = {};
    config =  {
                          // timeout: 2000,
                          showProgressBar: false,
                          closeOnClick: false,
                          pauseOnHover: true,
                          position: SnotifyPosition.centerTop
                        };
    return config;
  }

}
