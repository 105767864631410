import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment'

@Pipe({
    name: 'PunchDetailsDatePipe'
})

export class PunchDetailsDatePipe implements PipeTransform {

    transform(value: any): any {
        return moment(value).format('MM/DD/YYYY');
    }
}