import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EmployeeInfo } from 'src/app/models/EmployeeInfo';

@Injectable({
    providedIn: 'root'
})
export class EmployeeSharedService {
    employeeInfo: BehaviorSubject<EmployeeInfo> = new BehaviorSubject(new EmployeeInfo());

    updateEmployeeInfo(employeeInfo: EmployeeInfo): void {
        this.employeeInfo.next(employeeInfo);
    }
}