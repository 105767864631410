import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'LMS 2.0';

  constructor() { }

  ngOnInit() {
    // this.router.navigate([''])
  }
}
