import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ChartsModule } from 'ng2-charts';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SurveyComponent } from './components/survey/survey.component';
import { PunchdetailsComponent } from './components/punchdetails/punchdetails.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './guards/auth-interceptor';
import { ErrorComponent } from './components/error/error.component';
import { DefaultimageDirective } from './directives/defaultimage.directive';
import { NgxLoadingModule } from 'ngx-loading';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OwlDateTimeIntl } from 'ng-pick-datetime/picker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { SmarttabledatepickerComponent, SmartTableDatepickerRenderComponent } from './shared/Components/smarttabledatepicker/smarttabledatepicker.component';
import { BooleanPipe } from './shared/pipes/boolean.pipe';
import { SharedModule } from './shared/shared.module';
import { LoginComponent } from './login/login.component';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { LeaveDetailsComponent } from './components/leave-details/leave-details.component';
import { HolidayListComponent } from './components/holiday-list/holiday-list.component';
import { PoliciesComponent } from './components/policies/policies.component';
import { ManagerReportComponent } from './components/punchdetails/manager-report/manager-report.component';
import { MisspunchComponent } from './components/punchdetails/misspunch/misspunch.component';

export class DefaultIntl extends OwlDateTimeIntl { }
ToastDefaults.global.maxOnScreen = 1;
@NgModule({
  declarations: [
    AppComponent,
    SurveyComponent,
    PunchdetailsComponent,
    ErrorComponent,
    DefaultimageDirective,
    SmarttabledatepickerComponent,
    SmarttabledatepickerComponent,
    SmartTableDatepickerRenderComponent,
    BooleanPipe,
    LoginComponent,
    LeaveDetailsComponent,
    HolidayListComponent,
    PoliciesComponent,
    ManagerReportComponent,
    MisspunchComponent
  ],
  entryComponents: [
    SmarttabledatepickerComponent,
    SmartTableDatepickerRenderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ChartsModule,
    SnotifyModule,
    NgxLoadingModule.forRoot({}),
    TimepickerModule.forRoot(),
    PopoverModule.forRoot(),
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: 'SnotifyToastConfig',
      useValue: ToastDefaults,
    },
    SnotifyService
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule { }
