import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';

@Pipe({
  name: 'timeFormat'
})
export class HoursFormatPipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {
  }

  transform(value: any, format?: string): any {
    if (!value) {
      return '';
    }
    format = format || 'HH:mm';
    return value && typeof(value) === 'object' ? this.datePipe.transform(value, format) : '';
  }

}