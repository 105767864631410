import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { SnotifyService } from 'ng-snotify';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DateRange } from 'src/app/models/PunchDetailModels/daterange';
import { PayrollPeriods } from 'src/app/models/ReportsModels/PayrollPeriods';
import { PunchdetailsService } from 'src/app/service/punchdetails/punchdetails.service';
import { CommonHelper } from 'src/app/shared/Helpers/common-helper';
import * as _ from "lodash";


@Component({
  selector: 'lms-misspunch',
  templateUrl: './misspunch.component.html',
  styleUrls: ['./misspunch.component.scss']
})
export class MisspunchComponent implements OnInit {

  @Input() public set selectedPayRoll(val:any) {}


  missPunch: FormGroup = new FormGroup({
    FromDate:new FormControl('',Validators.required),
    ToDate:new FormControl('',Validators.required)

  })

  error: any = { isError: false, errorMessage: '' };

  toasterConfig = CommonHelper.toasterSettings();

  employeeID: string

  settings: { singleSelection: boolean; idField: string; textField: string; enableCheckAll: boolean; selectAllText: string; unSelectAllText: string; allowSearchFilter: boolean; limitSelection: number; clearSearchFilter: boolean; maxHeight: number; itemsShowLimit: number; searchPlaceholderText: string; noDataAvailablePlaceholderText: string; closeDropDownOnSelection: boolean; showSelectedItemsAtTop: boolean; defaultOpen: boolean; };
  deptData: { deptId: number; deptName: string; }[];
  monthlyCutOffPeriod: PayrollPeriods[] = [];
  currentPayRollEndDate: string;
  currentPayRollStartDate: string;
  timeSheetEmployeeID: string = '';

  payValues: { Id: number, StartDate: string, EndDate: string, Period: string }[] = [];


  constructor(private _punchservice: PunchdetailsService,private sanitizer: DomSanitizer,  private ngxService: NgxUiLoaderService,    private snotifyService: SnotifyService) { }

  ngOnInit() {
    this.missPunch = new FormGroup({

      FromDate:new FormControl('',Validators.required),
      ToDate:new FormControl('',Validators.required)
    })
    // this.getMonthlyCutOffDates()

    this.settings = {
      singleSelection: false,
      idField: 'deptId',
      textField: 'deptName',
      enableCheckAll: true,
      selectAllText: 'Choose All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 197,
      itemsShowLimit: 3,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: '',
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false,
    };



  }



resetForm(){
  this.missPunch.reset();
}

  reportDownload() {
    this.ngxService.start();

    const formValues = this.missPunch.getRawValue();
    console.log(formValues,"incoming fields");
    const formdata = new FormData()
    let EmpID: string = localStorage.getItem('id');

    let finaldata ={
      startDate:  moment(this.missPunch.controls['FromDate'].value).format('MM-DD-YYYY'),
      endDate:  moment(this.missPunch.controls['ToDate'].value).format('MM-DD-YYYY'),

      userID:EmpID
    }



     this._punchservice.downloadMissPunch(finaldata).subscribe((res:any)=>{

      if(!res.isSucceed){
        this.ngxService.stop();


        this.snotifyService.error(res.msg, this.toasterConfig);
      }
      else if(res.isSucceed){
        this.ngxService.stop();

        console.log("is succeddd")
        this.downloadFileInNewwindow(res['data'], 'MisspunchReport.xlsx');

      }

    })
  }
  downloadFileInNewwindow(bytes: string, fileName: string): void {
    const arrayBuffer = this.base64ToArrayBuffer(bytes);
    const blob = new Blob([arrayBuffer]);

    if (window.navigator.msSaveOrOpenBlob) { // For IE:
      navigator.msSaveBlob(blob, fileName);
    } else { // For other browsers:
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(link.href);
    }
  }
  base64ToArrayBuffer(base64: string) {
    const binaryString = window.atob(base64); // Comment this if not using base64
    const bytes = new Uint8Array(binaryString.length);
    return bytes.map((byte, i) => binaryString.charCodeAt(i));
  }




  compareTwoDates(): boolean {
    this.error.errorMessage = '';
    if (new Date(this.missPunch.controls['ToDate'].value) < new Date(this.missPunch.controls['FromDate'].value)) {
      console.log(this.missPunch.controls['ToDate'].value,"Todate");
      console.log(this.missPunch.controls['FromDate'].value,"fromDate");

      this.error = { isError: true, errorMessage: 'End Date cannot before start date' };
      return true;
    }
    else {
      this.error={ isError: false, errorMessage: 'End Date cannot before start date' }
      return false;
    }

  }



}
