import { Component, OnInit } from '@angular/core';
import { PunchdetailsService } from '../../service/punchdetails/punchdetails.service';
import * as moment from "moment";
import { Departments } from 'src/app/models/PunchDetailModels/department';
import { Employees } from 'src/app/models/PunchDetailModels/employees';
import { FormGroup, FormControl } from '@angular/forms';
import { DateRange } from '../../models/PunchDetailModels/daterange'
import { DatePeriod } from 'src/app/models/DatePeriod';
import * as _ from 'lodash'
import { PayrollPeriods } from 'src/app/models/ReportsModels/PayrollPeriods';
import { DashboarddetailsService } from 'src/app/service/dashboard/dashboarddetails.service';
import { EmployeeSharedService } from 'src/app/shared/services/employee-shared-service';
import { EmployeeInfo } from 'src/app/models/EmployeeInfo';
import { LeaveBalance } from 'src/app/models/DashboardModels/leavebalance';

@Component({
    selector: 'lms-leave-details',
    templateUrl: './leave-details.component.html',
    styleUrls: ['./leave-details.component.scss']
})
export class LeaveDetailsComponent implements OnInit {

    loading: boolean = false;
    employeeID: string;
    employeeRole: any;
    timeSheetEmployeeID: string = '';
    timeSheetEmployeeName: string = '';
    isManager: boolean = false
    departments: Departments[] = [];
    employees: Employees[] = [];
    isEmployeeDisabled: boolean = true;
    departmentsForm: FormGroup = new FormGroup({
        departmentName: new FormControl(null, null)
    });
    headElements = ['Emp ID', 'Emp Name', 'Earned', 'Casual', 'Sick', 'CompOff', 'Wedding', 'Maternity', 'Bereavement', 'Paternity', 'Floating'];
    leaveBalance: LeaveBalance[] = [];

    constructor(private _punchservice: PunchdetailsService,
        private _dashboardService: DashboarddetailsService,
        private _employeeSharedService: EmployeeSharedService) { }

    ngOnInit(): void {
        this.setLocalStorageData();
        let roleSplit = this.employeeRole ? this.employeeRole.split(",") : '';
        if (roleSplit.includes("MANAGER") || (roleSplit.includes("HR") && roleSplit.includes("MANAGER"))) {
            this.isManager = true;
            this.getDepartments();
        }

        this._employeeSharedService.employeeInfo.subscribe((info: EmployeeInfo) => {
            if (!info.empName && !info.reportingManagerId)
                this.getEmployeeInfo();
        });
    }

    setLocalStorageData(): void {
        this.employeeID = localStorage.getItem('id');
        this.employeeRole = localStorage.getItem('roles');
    }

    getEmployeeInfo() {
        this._dashboardService.getEmployeeInfo(localStorage.getItem('id')).subscribe((res: EmployeeInfo) => {
            this._employeeSharedService.updateEmployeeInfo(res);
            localStorage.setItem('EmployeeName', res.empName);
        })
    }

    getDepartments(): void {
        this._punchservice.getDepartments(this.employeeID).subscribe((res: Departments[]) => {
            this.departments = _.sortBy(res, 'deptName');
        }, (error) => {
            console.log('DepartmentAPIError:', error)
        })
    }

    getLeaveDetails(deptId: string): void {
        if (deptId) {
            this.loading = true;
            this._dashboardService.getLeaveBalanceByDept(deptId, this.employeeID).subscribe((res: LeaveBalance[]) => {
                this.leaveBalance = res;
                this.loading = false;
            }, (error) => {
                this.loading = false;
            })
        }
    }
}
