import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PunchdetailsComponent } from './components/punchdetails/punchdetails.component';
import { ReportsGuard } from './guards/reports-guard/reports-guard';
import { LoginComponent } from './login/login.component';
import { LeaveDetailsComponent } from './components/leave-details/leave-details.component';
import { ManagerGuard } from './guards/manager-guard/manager-guard';
import { HolidayListComponent } from './components/holiday-list/holiday-list.component';
import { PoliciesComponent } from './components/policies/policies.component';


const routes: Routes = [
  {
    path: '', redirectTo: 'dashboard', pathMatch: 'full'
  },
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'dashboard', loadChildren: () => import('./components/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'punchdetails', component: PunchdetailsComponent
  },
  {
    path: 'holiday-list', component: HolidayListComponent
  },
  {
    path: 'policies', component: PoliciesComponent
  },
  {
    path: 'leavedetails', canActivate: [ManagerGuard], component: LeaveDetailsComponent
  },
  {
    path: 'applyallowance', loadChildren: () => import('./components/apply-leave-permission-modal/apply-leave-permission-modal.module').then(m => m.ApplyLeavePermissionModalModule)
  },
  {
    path: 'admin', canActivate: [ReportsGuard], loadChildren: () => import('./components/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'reports', canActivate: [ReportsGuard], loadChildren: () => import('./components/reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: '**', redirectTo: 'dashboard'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
