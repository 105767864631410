export const environment = {
  production: true,
  // ApiBaseUrl: 'http://192.168.4.35:5011'
  // ApiBaseUrl: 'http://192.168.1.150:8090'
  // ApiBaseUrl: 'https://localhost:44353'
  // ApiBaseUrl: 'http://192.168.2.62:8070'

  //prod
  // ApiBaseUrl: 'http://192.168.1.108:1998',
  // ApiBaseUrl: 'http://192.168.3.109:1044'

  ApiBaseUrl: 'https://lmsapi.srinsofttech.com',
  // ApiBaseUrl: 'https://192.168.3.98:1011',
  // ApiBaseUrl: 'http://192.168.1.108:8083',


};
