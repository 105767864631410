import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'

@Injectable({
    providedIn: 'root'
})
export class ReportsGuard implements CanActivate {

    constructor(private router: Router) {
    }

    canActivate(): boolean {
      console.log("hr report navigate");
        let groupName: string = localStorage.getItem('roles');
        console.log(groupName,"from authguard");
        let splitRoles = groupName.split(",")
        if (splitRoles.includes("HR") || splitRoles.includes("MANAGER"))
            return true;
        else {
            this.router.navigate(['dashboard']);
            return false;
        }

    }
}
