import { Component, OnInit, Input } from '@angular/core';
import { DefaultEditor, ViewCell } from 'ng2-smart-table';
import * as moment from 'moment'

@Component({
  selector: 'lms-smarttabledatepicker',
  templateUrl: './smarttabledatepicker.component.html',
  styleUrls: ['./smarttabledatepicker.component.scss']
})
export class SmarttabledatepickerComponent extends DefaultEditor implements OnInit {

  @Input() placeholder: string = 'Choose a Date';

  // @Input() min: Date; // Defaults to now(rounded down to the nearest 15 minute mark)

  // @Input() max: Date; // Defaults to 1 month after the min
  

  stringValue;
  inputModel: Date;
  constructor() {
    super();
  }

  ngOnInit() {
    if (this.cell.newValue) {
      let cellValue = new Date(this.cell.newValue);
      this.inputModel = cellValue;
      this.cell.newValue = this.inputModel.toISOString();
    }
    else{
      let cellValue = new Date();
      this.inputModel = cellValue;
      this.cell.newValue = this.inputModel.toISOString();
    }
  }
  onChange() {
    if (this.inputModel) {
      this.cell.newValue = this.inputModel.toISOString();
    }
  }

}

@Component({
  template: `{{value | date:'short'}}`,
})
export class SmartTableDatepickerRenderComponent implements ViewCell, OnInit {
  @Input() value: string;
  @Input() rowData: any;

  constructor() { }

  ngOnInit() {
    
  }

}
