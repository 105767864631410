import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'lms-holiday-list',
    templateUrl: './holiday-list.component.html',
    styleUrls: ['./holiday-list.component.scss']
})
export class HolidayListComponent implements OnInit {

    ngOnInit(): void {

    }
}