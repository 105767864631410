import { Injectable } from '@angular/core';
import { PunchDetailsRequest } from '../../models/PunchDetailModels/punchdetailsrequest'
import { PunchDetailsResponse } from '../../models/PunchDetailModels/PunchDetailsResponse';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Departments } from 'src/app/models/PunchDetailModels/department';
import { Employees } from 'src/app/models/PunchDetailModels/employees';
import { DatePeriod } from 'src/app/models/DatePeriod';
import { PayrollPeriods } from 'src/app/models/ReportsModels/PayrollPeriods';


@Injectable({
  providedIn: 'root'
})
export class PunchdetailsService {
  baseURL: string = environment.ApiBaseUrl;
  constructor(private _http: HttpClient) { }

  getPunchData(punchDetailsRequest: PunchDetailsRequest): Observable<PunchDetailsResponse[]> {

    return this._http.post<PunchDetailsResponse[]>(this.baseURL + '/api/PunchDetails/GetPunchDetails', punchDetailsRequest);
  }

  getDepartments(employeeID: string): Observable<Departments[]> {
    return this._http.get<Departments[]>(`${this.baseURL}/api/PunchDetails/GetDepartments?EmpId=${employeeID}`);
  }

  getEmployees(deptID: string): Observable<Employees[]> {
    return this._http.get<Employees[]>(`${this.baseURL}/api/PunchDetails/GetEmployees?DeptId=${deptID}`);
  }

  getEmployeesByDept(employeeID: string, deptID: string): Observable<Employees[]> {
    return this._http.get<Employees[]>(`${this.baseURL}/api/PunchDetails/GetEmployees?EmpId=${employeeID}&DeptId=${deptID}`);
  }

  getPayRollCutOffDates(): Observable<DatePeriod[]> {
    return this._http.get<DatePeriod[]>(`${this.baseURL}/api/Reports/GetMonthlyCutOffDates`)
  }

  getMonthlyPayRollPeriods(): Observable<PayrollPeriods[]> {
    return this._http.get<PayrollPeriods[]>(`${this.baseURL}/api/HR/GetPayRollPeriods`)
  }

  getEmployeesByEmployee(employeeID: string, deptID: string): Observable<Employees[]> {
    return this._http.get<Employees[]>(`${this.baseURL}/api/PunchDetails/GetReportees?EmpId=${employeeID}&DeptId=${deptID}`);
  }

  getDepartmentForreport(deptID:string){
    return this._http.get<Departments>(`${this.baseURL}/api/Reports/GetDepartmentsForManager?DeptId=${deptID}`);

  }

  downloadManagerReport(downloadReq:any){
    return this._http.post<PunchDetailsResponse[]>(this.baseURL + '/api/Reports/GetLOPReportForManager', downloadReq);
  }

  downloadMissPunch(downloadReq:any){

    return this._http.post<PunchDetailsResponse[]>(this.baseURL + '/api/Reports/GetMissedReport', downloadReq);


  }



}
