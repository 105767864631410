import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Departments } from 'src/app/models/PunchDetailModels/department';
import { Employees, EmployeeRoles, EmployeeDesignation, ReportingManager } from 'src/app/models/Employees';
import { AddEmployee } from 'src/app/models/AddEmployee';
import { UpdateEmployee, UpdateStatusEmployee } from 'src/app/models/UpdateEmployee';
import { EmployeeFormModel } from 'src/app/models/EmployeeFormModel';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  baseURL: string = environment.ApiBaseUrl;
  constructor(private _http: HttpClient) { }
  public allEmployees = new BehaviorSubject({});



  getDepartments(): Observable<Departments[]> {
    return this._http.get<Departments[]>(`${this.baseURL}/api/HR/GetDepartments`);
  }
  getEmployeeIDs(): Observable<Employees[]> {
    return this._http.get<Employees[]>(`${this.baseURL}/api/HR/GetEmployeeIds`);
  }

  getRoles(): Observable<EmployeeRoles[]> {
    return this._http.get<EmployeeRoles[]>(`${this.baseURL}/api/HR/GetRoles`);
  }

  getReportingManagers(): Observable<ReportingManager[]> {
    return this._http.get<ReportingManager[]>(`${this.baseURL}/api/HR/GetReportingManagers`);
  }

  getDesignations(): Observable<EmployeeDesignation[]> {
    return this._http.get<EmployeeDesignation[]>(`${this.baseURL}/api/HR/GetDesignation`);
  }

  addEmployee(empData: AddEmployee): Observable<any> {
    return this._http.post(`${this.baseURL}/api/HR/AddEmployee`, empData);
  }

  updateStatus(empData: UpdateStatusEmployee):Observable<any> {
    console.log(empData)
    return this._http.put(`${this.baseURL}/api/HR/UpdateEmployeeStatus`, empData);
  }

  getEmployees(): Observable<Employees[]> {
    return this._http.get<Employees[]>(`${this.baseURL}/api/HR/GetEmployees`);
  }

  getSingleEmoloyee(empID: string): Observable<Employees[]> {
    return this._http.get<Employees[]>(`${this.baseURL}/api/Employee/GetEmployeeInfo?EmpId=${empID}`);
  }

  getInActiveEmployees(): Observable<Employees[]> {
    return this._http.get<Employees[]>(`${this.baseURL}/api/HR/GetInActiveEmployees`);
  }

  updateEmployee(empData: UpdateEmployee): Observable<any> {
    return this._http.put(`${this.baseURL}/api/HR/UpdateEmployee`, empData);
  }

  getAllEmployeesLeaveBalance(): Observable<Employees[]> {
    return this._http.get<Employees[]>(`${this.baseURL}/api/HR/GetAllEmployeesLeaveBalanceInfo`);
  }

  getCompanies(): Observable<any[]> {
    return this._http.get<any[]>(`${this.baseURL}/api/Employee/GetCompanies`);
  }

  getDivisions(): Observable<any[]> {
    return this._http.get<any[]>(`${this.baseURL}/api/Employee/GetDivisions`);
  }

  getEmployeeInfo(empId:string): Observable<EmployeeFormModel> {
    return this._http.get<EmployeeFormModel>(`${this.baseURL}/api/HR/GetEmployeeInfo?EmpId=${empId}`);
  }
}
