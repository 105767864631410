import { Pipe, PipeTransform } from '@angular/core';
import { pipe } from 'rxjs';

@Pipe({

  name: 'leaveStatus'

})

export class PunchDetailsStatusPipe implements PipeTransform {
  transform(value: any, ...args: any[]) {

    return value = 'present';

  }

}
