import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LeaveBalance } from '../../models/DashboardModels/leavebalance'
import { LeaveStatus } from '../../models/DashboardModels/leavestatus'
import { MissingTimesheetRequest } from '../../models/DashboardModels/missingtimesheetrequest';
import { MissingTimesheetResponse } from '../../models/DashboardModels/missingtimesheetresponse';
import { UpcomingBirthdays } from 'src/app/models/DashboardModels/upcomingbirthdays';
import { LeaveTypes } from 'src/app/models/LeaveTypes';
import { ApplyLeave } from 'src/app/models/ApplyLeave';
import { EmployeeInfo } from 'src/app/models/EmployeeInfo';
import { PendingApprovals } from 'src/app/models/PendingApprovals';
import { ApproveLeave } from '../../models/DashboardModels/approveleave';

@Injectable({
  providedIn: 'root'
})
export class DashboarddetailsService {
  baseURL: string = environment.ApiBaseUrl;
  public lmsLockStatus = new BehaviorSubject<boolean>(false);

  constructor(private _http: HttpClient) { }

  getLeaveBalance(empID: String): Observable<LeaveBalance> {
    return this._http.get<LeaveBalance>(`${this.baseURL}/api/Employee/GetLeaveBalance?EmpId=${empID}`);
  }

  getLeaveStatus(empID: string): Observable<any> {
    return this._http.get<any>(`${this.baseURL}/api/Employee/GetLeaveStatus?EmpId=${empID}`);
  }

  updateLeaveStatus(updateLeaveData: ApproveLeave): Observable<any> {
    return this._http.put(`${this.baseURL}/api/Employee/UpdateLeaveStatus`, updateLeaveData);
  }

  approveAllLeaveStatus(updateLeaveData): Observable<any> {
    return this._http.put(`${this.baseURL}/api/Employee/UpdateAllLeaveStatus`, updateLeaveData);
  }

  getMissingTimesheet(userInfo: MissingTimesheetRequest): Observable<MissingTimesheetResponse[]> {
    return this._http.post<MissingTimesheetResponse[]>(`${this.baseURL}/api/TimeSheetInfo/GetMissingTimeSheetInfo`, userInfo);
  }

  getUpcomingBirthdays(): Observable<UpcomingBirthdays[]> {
    return this._http.get<UpcomingBirthdays[]>(`${this.baseURL}/api/Employee/GetUpComingBirthDays`);
  }

  getLMSLockStatus(): Observable<any> {
    return this._http.get<any>(`${this.baseURL}/api/HR/GetLMSLockStatus`)
  }

  getLeaveTypes(): Observable<LeaveTypes[]> {
    return this._http.get<LeaveTypes[]>(`${this.baseURL}/api/Employee/GetLeaveTypes`);
  }

  applyLeave(leaveInfo: ApplyLeave): Observable<any> {
    return this._http.post<ApplyLeave>(`${this.baseURL}/api/Employee/ApplyLeave`, leaveInfo);
  }

  applyPermission(permissionInfo: ApplyLeave): Observable<any> {
    return this._http.post<ApplyLeave>(`${this.baseURL}/api/Employee/ApplyPermission`, permissionInfo);
  }

  getEmployeeInfo(empID: string): Observable<EmployeeInfo> {
    return this._http.get<EmployeeInfo>(`${this.baseURL}/api/Employee/GetEmployeeInfo?EmpId=${empID}`);
  }

  getPendingApprovals(empID: string): Observable<PendingApprovals[]> {
    return this._http.get<PendingApprovals[]>(`${this.baseURL}/api/Employee/GetPendingApprovals?ManagerId=${empID}`);
  }

  getLogHours(empID: string, date: string): Observable<number> {
    return this._http.get<number>(`${this.baseURL}/api/TimeSheetInfo/GetLogHours?EmpId=${empID}&date=${date}`);
  }

  getLeaveBalanceByDept(deptID: String,empID): Observable<LeaveBalance[]> {
    return this._http.get<LeaveBalance[]>(`${this.baseURL}/api/Employee/GetLeaveBalanceByDeptId?DeptId=${deptID}&EmpId=${empID}`);
  }

  getAbsentReport(reportStatus:boolean){

return this._http.get(`${this.baseURL}/api/Reports/GetAbsentReport?downloadExcel=${reportStatus}`,{responseType: 'blob'});
  }

}
